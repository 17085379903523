<template>
  <div class="levelOneDiv" style="background-color: #fff;">
    <!-- <div style="padding: 10px 20px 10px; ">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item v-for="item in breadList" :key="item.key">{{ item.value }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div> -->
    <div v-if="!componentName" class="table_box">
      <div class="box1">
        <templateTree ref="templateTree" :treeData="treeData" :dck="dck" @refreshNode="refreshNode" />
      </div>

      <div class="box2">
        <div class="table_right">
          <!-- 搜索筛选 -->
          <el-form
            :inline="true"
            :model="searchObj"
            :rules="rules"
            ref="form"
            style="align-items: center;margin-bottom: 4px;"
            class="user-search flex-between"
            @submit.native.prevent
          >
            <div>
              <el-form-item label="预算年度" prop="year">
                <budgetYear ref="budgetYear" @refreshYear="refreshYear" />
              </el-form-item>

              <el-form-item>
                <el-button size="mini" plain icon="el-icon-search" @click="search"></el-button>
              </el-form-item>

              <el-form-item>
                <el-button size="mini" plain icon="el-icon-refresh-right" @click="reset"></el-button>
              </el-form-item>
            </div>

            <div>
              <el-form-item>
                <el-button size="mini" type="primary" icon="el-icon-plus" @click="handleAdd('add', {})">新建</el-button>
              </el-form-item>
              <el-form-item>
                <el-button size="mini" type="primary" plain icon="el-icon-download" @click="importExcel()"
                  >导入</el-button
                >
              </el-form-item>
              <el-form-item>
                <el-button size="mini" type="primary" plain icon="el-icon-upload2" @click="exportExcel()"
                  >导出</el-button
                >
              </el-form-item>

              <!-- <el-form-item>
                <el-button size="mini" type="primary" plain icon="el-icon-s-data" @click="handleView('charts', {})"
                  >查看统计图</el-button
                >
              </el-form-item> -->
            </div>
          </el-form>

          <div style="position: relative;">
            <el-table
              v-loading="loading"
              ref="table"
              :data="listData"
              style="width: 100%;position: absolute;"
              height="740px"
            >
              <el-table-column fixed type="index" label="序号" width="60" align="center">
                <template slot-scope="scope">
                  <span v-if="scope.$index != 0">{{
                    (pageparm.currentPage - 1) * pageparm.pageSize + scope.$index
                  }}</span>
                </template>
              </el-table-column>

              <el-table-column fixed align="center" prop="city" width="80" label="市（州）"></el-table-column>
              <el-table-column fixed align="center" prop="county" width="110" label="县（市、区）"></el-table-column>
              <el-table-column
                fixed
                align="center"
                prop="projectName"
                width="140"
                style="word-break: normal"
                label="项目名称"
              >
                <template slot-scope="scope">
                  <el-tooltip placement="top-start">
                    <div slot="content" class="text_warp">
                      {{ scope.row.projectName }}
                    </div>
                    <div style="color: #000" class="text_els">
                      {{ scope.row.projectName }}
                    </div>
                  </el-tooltip>
                </template>
              </el-table-column>

              <el-table-column
                align="center"
                style="word-break: normal"
                prop="projectCategory"
                width="120"
                label="项目类别"
              >
                <template slot-scope="scope">
                  <el-tooltip placement="top-start">
                    <div slot="content" class="text_warp">
                      {{ scope.row.projectCategory }}
                    </div>
                    <div style="color: #000" class="text_els">
                      {{ scope.row.projectCategory }}
                    </div>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column
                prop="budgetDocumentNumber"
                width="220"
                align="center"
                label="对应省级分解下达预算文件文号"
              ></el-table-column>

              <el-table-column align="center" label="项目计划批复情况">
                <el-table-column align="center" prop="approvalDate" width="130" label="批复时间（格式如2022/01/01）">
                  <template slot-scope="scope">
                    <div v-if="scope.$index != 0">
                      {{ scope.row.approvalDate ? timeFormate(scope.row.approvalDate, 'YYYY/MM/DD') : '' }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="approvalDepartment"
                  style="word-break: normal"
                  width="130"
                  label="批复部门"
                >
                  <template slot-scope="scope">
                    <el-tooltip placement="top-start">
                      <div slot="content" class="text_warp">
                        {{ scope.row.approvalDepartment }}
                      </div>
                      <div style="color: #000" class="text_els">
                        {{ scope.row.approvalDepartment }}
                      </div>
                    </el-tooltip>
                  </template>
                </el-table-column>

                <el-table-column align="center" label="批复资金（万元）">
                  <el-table-column align="center" width="130" prop="centralFunds" label="中央资金">
                    <template slot-scope="scope">
                      {{ formatToTenThousandWithCustomCommas(scope.row.centralFunds) }}
                    </template>
                  </el-table-column>
                  <el-table-column align="center" width="130" prop="provincialFunds" label="省级库区资金">
                    <template slot-scope="scope">
                      {{ formatToTenThousandWithCustomCommas(scope.row.provincialFunds) }}
                    </template>
                  </el-table-column>
                  <el-table-column align="center" width="130" prop="otherFunds" label="其他资金">
                    <template slot-scope="scope">
                      {{ formatToTenThousandWithCustomCommas(scope.row.otherFunds) }}
                    </template>
                  </el-table-column>
                </el-table-column>
              </el-table-column>

              <el-table-column align="center" label="项目实施进度">
                <el-table-column align="center" label="开工情况">
                  <el-table-column align="center" width="110" prop="isStarted" label="是否开工（是/否）">
                    <template slot-scope="scope">
                      <div v-if="scope.$index != 0">
                        {{ scope.row.isStarted ? '是' : '否' }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column align="center" width="130" prop="startDate" label="开工时间（格式如2022/01/01）">
                    <template slot-scope="scope">
                      <div v-if="scope.$index != 0">
                        {{ scope.row.startDate ? timeFormate(scope.row.startDate, 'YYYY/MM/DD') : '' }}
                      </div>
                    </template>
                  </el-table-column>
                </el-table-column>

                <el-table-column align="center" label="完工情况">
                  <el-table-column align="center" width="110" prop="isCompleted" label="是否完工（是/否）">
                    <template slot-scope="scope">
                      <div v-if="scope.$index != 0">
                        {{ scope.row.isCompleted ? '是' : '否' }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    align="center"
                    width="130"
                    prop="completionDate"
                    label="完工时间（格式如2022/01/01）"
                  >
                    <template slot-scope="scope">
                      <div v-if="scope.$index != 0">
                        {{ scope.row.completionDate ? timeFormate(scope.row.completionDate, 'YYYY/MM/DD') : '' }}
                      </div>
                    </template>
                  </el-table-column>
                </el-table-column>

                <el-table-column align="center" label="验收情况">
                  <el-table-column align="center" width="110" prop="isAccepted" label="是否验收（是/否）">
                    <template slot-scope="scope">
                      <div v-if="scope.$index != 0">
                        {{ scope.row.isAccepted ? '是' : '否' }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    align="center"
                    width="130"
                    prop="acceptanceDate"
                    label="验收时间（格式如2022/01/01）"
                  >
                    <template slot-scope="scope">
                      <div v-if="scope.$index != 0">
                        {{ scope.row.acceptanceDate ? timeFormate(scope.row.acceptanceDate, 'YYYY/MM/DD') : '' }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    align="center"
                    width="130"
                    prop="isAcceptanceQualified"
                    label="验收是否合格（是/否）"
                  >
                    <template slot-scope="scope">
                      <div v-if="scope.$index != 0">
                        {{ scope.row.isAcceptanceQualified ? '是' : '否' }}
                      </div>
                    </template>
                  </el-table-column>
                </el-table-column>
              </el-table-column>

              <el-table-column align="center" label="移民资金完成情况">
                <el-table-column prop="currentYearFunds" align="center" width="150" :label="title1">
                  <template slot-scope="scope">
                    {{ formatToTenThousandWithCustomCommas(scope.row.currentYearFunds) }}
                  </template>
                </el-table-column>
                <el-table-column align="center" prop="nextYearFunds" width="150" :label="title2">
                  <template slot-scope="scope">
                    {{ formatToTenThousandWithCustomCommas(scope.row.nextYearFunds) }}
                  </template>
                </el-table-column>
              </el-table-column>

              <el-table-column align="center" label="实施效益">
                <el-table-column
                  align="center"
                  prop="benefitedCommunitys"
                  width="120"
                  label="实际收益移民村（个）"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="benefitedPopulation"
                  width="120"
                  label="实际收益移民（人次）"
                ></el-table-column>
              </el-table-column>

              <el-table-column align="center" width="120" show-overflow-tooltip prop="remarks" label="备注">
              </el-table-column>

              <el-table-column align="center" fixed="right" label="操作" width="90">
                <template slot-scope="scope">
                  <div class="flex">
                    <el-link v-if="scope.$index != 0" type="primary" @click="handleView('view', scope.row)"
                      >查看</el-link
                    >
                    &nbsp; &nbsp;
                    <el-link v-if="scope.$index != 0" type="danger" @click="handleDelete(scope.$index, scope.row)"
                      >删除</el-link
                    >
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
        </div>
      </div>
    </div>

    <formHandle
      v-else="componentName"
      ref="formHandle"
      @refreshTable="refreshTable"
      @handleBack="handleBack"
    ></formHandle>

    <tempalteImport ref="tempalteImport" @refreshTable="refreshTable" />
    <!-- 分页组件 -->
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import templateTitle from '@/components/templateView/templateTitle.vue'
import budgetYear from '@/components/templateView/budgetYear.vue'
import tempalteImport from '@/components/templateView/tempalteImport.vue'
import formHandle from '@/views/postImmigrationService/policyIE/ImplementSituation/laterStage/components/formHandle.vue'
import templateTree from '@/components/templateView/templateTree.vue'

import { _getLevelRegion } from '@/api/keepHome'

import {
  _ProjectImplementationPage,
  _ProjectImplementationExport,
  _ProjectImplementationDelete
} from '@/api/modular/postImmigrationService/policy/laterStage.js'
export default {
  data() {
    return {
      type: '',
      showActions: false,
      selectedRows: [], // 当前选中的行
      // 分页参数
      pageparm: {
        currentPage: 1,
        pageSize: 10,
        total: null
      },
      loading: false, //是显示加载
      searchObj: {
        year: ''
      },
      rules: {
        year: [{ required: true, message: '请选择预算年度', trigger: 'blur' }]
      },
      data: [],
      listData: [],
      treeData: [],
      breadList: [
        {
          id: Math.random(),
          value: '列表页'
        }
      ],
      componentName: '',
      downloadObj: {},
      dck: [],
      OrgId: null,
      IsLevel: false,
      OrgPids: null,
      years: [],
      title1: '',
      title2: ''
    }
  },
  // 注册组件
  components: {
    Pagination,
    templateTitle,
    formHandle,
    tempalteImport,
    budgetYear,
    templateTree
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.getLevelRegion()
      //this.getPage()
    },
    handleResize() {
      this.$nextTick(() => {
        this.$refs.table.doLayout()
      })
    },
    getLevelRegion(val) {
      let params = {
        startLevel: 2,
        endLevel: 3,
        inclusionLevel: true
      }
      _getLevelRegion(params).then(res => {
        if (res.code == 200) {
          this.treeData = res.data
          let provincialCenterId = this.treeData[0].id //默认展开第一个节点
          this.dck = [provincialCenterId]
          this.downloadObj = this.treeData[0]
        }
      })
    },
    refreshNode(obj, IsLevel) {
      this.OrgId = obj.id
      this.downloadObj = obj
      this.IsLevel = IsLevel
      this.OrgPids = obj.pids
      this.pageparm = {
        currentPage: 1,
        pageSize: 10,
        total: null
      }
      this.getPage()
    },
    refreshYear(year, years) {
      this.searchObj.year = year

      this.title1 = `截至${this.searchObj.year}年12月底完成资金（万元）`
      this.title2 = `截至${this.searchObj.year + 1}年3月底完成资金（万元）`

      this.years = years
      this.pageparm.currentPage = 1
      this.getPage()
    },
    exportExcel() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          console.log(this.downloadObj, '====this.downloadObj===')
          let params = {
            year: this.searchObj.year,
            OrgId: this.OrgId, //行政区域编号
            OrgName: this.downloadObj.name, //行政区域名称
            OrgPids: this.downloadObj.pids //行政区域编号path
          }

          console.log(params, '=============params================')
          // return
          _ProjectImplementationExport(params).then(res => {
            if (res.status == 200) {
              this.downloadfile(res)
            } else {
              this.$message.error('导出失败')
            }
          })
        } else {
          this.$message.error('请选择导出年度')
        }
      })
    },
    importExcel() {
      this.$refs.tempalteImport.view(this.searchObj.year, this.years, '2-2')
    },

    handleBack() {
      this.breadList.splice(1, 1)
      this.componentName = ''
    },
    handleNodeClick(data) {
      console.log(data, '----------handleNodeClick-------')
    },
    // 获取page列表
    getPage() {
      const { currentPage, pageSize, total } = this.pageparm
      this.loading = true
      const params = {
        pageNo: currentPage,
        pageSize: pageSize,
        ...this.searchObj,
        OrgId: this.OrgId,
        OrgPids: this.OrgPids,
        IsLevel: this.IsLevel
      }

      // return
      _ProjectImplementationPage(params)
        .then(res => {
          if (res.code == 200) {
            this.listData = res.data.rows

            console.log(res, '======获取page页数据=======')

            this.listData = res.data.rows
            this.pageparm.total = res.data.totalRows

            if (res.data.totalRow) {
              this.listData.unshift(res.data.totalRow)
            }

            

            this.loading = false
          } else {
            this.loading = false
            this.$message.error('获取失败：' + res.message)
          }
        })
        .finally(res => {
          this.loading = false
        })
    },
    handleDelete(index, row) {
      this.$confirm('确定要删除吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const params = [{ id: row.id }]
          _ProjectImplementationDelete(params).then(res => {
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '操作成功!'
              })
              this.getPage()
            } else {
              this.$message({
                type: 'error',
                message: res.message
              })
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // 搜索事件
    search() {
      this.pageparm.currentPage = 1
      this.getPage()
    },
    reset() {
      let nowYear = new Date().getFullYear()
      if (nowYear <= 2023) {
        this.searchObj = {
          year: nowYear - 1
        }
      } else {
        this.searchObj = {
          year: nowYear - 2
        }
      }

      this.OrgId = null
      this.IsLevel = false
      this.OrgPids = null
      this.pageparm = {
        currentPage: 1,
        pageSize: 10,
        total: null
      }

      this.$refs.budgetYear.reset(this.searchObj.year)
      this.$refs.templateTree.resetLevel()

      this.getPage()
    },

    handleAdd(type, row) {
      let obj = {
        id: Math.random(),
        value: '新增'
      }
      this.breadList.splice(this.breadList.length, 0, obj)
      this.componentName = type
      this.type = type
      this.$nextTick(() => {
        this.$refs.formHandle.add(type)
      })
    },

    handleView(type, row) {
      let obj = {
        id: Math.random(),
        value: type == 'view' ? '详情' : '查看统计图'
      }
      this.breadList.splice(this.breadList.length, 0, obj)

      this.componentName = type
      this.type = type
      this.$nextTick(() => {
        this.$refs.formHandle.view(type, row)
      })
    },

    refreshTable() {
      this.getPage()
    },

    // 选择select
    handleSelectionChange(selection) {
      // console.log(selection);//点击的那行数据arr
      this.selectedRows = selection
      this.showActions = selection.length > 0
    },
    isSelect({ row }) {
      const checkIdList = this.selectedRows.map(item => item.data1)
      if (checkIdList.includes(row.data1)) {
        return {
          color: '#e17009'
        }
      }
    },

    // 分页插件事件
    callFather(parm) {
      this.pageparm.currentPage = parm.currentPage
      this.pageparm.pageSize = parm.pageSize
      this.getPage()
    },
    downloadfile(res) {
      var contentDisposition = res.headers['content-disposition']
      if (contentDisposition) {
        const regex = /filename*.+?\.xlsx/g
        const matches = contentDisposition.match(regex)
        const filename1 = matches[1].replace(/['"]/g, '')
        const startIndex = filename1.indexOf('filename*=UTF-8') + 'filename*=UTF-8'.length
        const result = filename1.substring(startIndex)
        const filename = result.replace(/^_+/g, '')
        var downloadElement = document.createElement('a')
        var href = window.URL.createObjectURL(res.data) // 创建下载的链接
        var reg = /^["](.*)["]$/g
        downloadElement.style.display = 'none'
        downloadElement.href = href
        downloadElement.download = decodeURI(filename.replace(reg, '$1')) // 下载后文件名
        document.body.appendChild(downloadElement)
        downloadElement.click() // 点击下载
        document.body.removeChild(downloadElement) // 下载完成移除元素
        window.URL.revokeObjectURL(href)
      } else {
        this.$message.error('导出失败')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/common/scss/postImmigrationService.scss';
@import '@/common/scss/elementui.scss';
::v-deep .el-form-item {
  margin-bottom: 0px;
}
</style>
